// modules
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// definitions
function SEO({ lang, title, description, route }) {
  const siteMetadata = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
          }
        }
      }
    `
  ).site.siteMetadata

  const helmetTitle = title || siteMetadata.title
  const helmetDescription = description || siteMetadata.description
  const helmetAuthor = siteMetadata.author
  const helmetTitleTemplate = title
    ? `${siteMetadata.title} | ${title}`
    : siteMetadata.title
  const helmetCanonicalUrl = route
    ? siteMetadata.siteUrl + route
    : siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={helmetTitle}
      titleTemplate={helmetTitleTemplate}
      link={[
        {
          rel: 'canonical',
          href: helmetCanonicalUrl,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: helmetDescription,
        },
        // open graph
        {
          property: `og:title`,
          content: helmetTitle,
        },
        {
          property: `og:description`,
          content: helmetDescription,
        },
        {
          property: `og:url`,
          content: helmetCanonicalUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: helmetTitle,
        },
        // twitter card
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: helmetTitle,
        },
        {
          name: `twitter:description`,
          content: helmetDescription,
        },
        {
          name: `twitter:creator`,
          content: helmetAuthor,
        },
      ]}
    />
  )
}

// props defaults
SEO.defaultProps = {
  lang: `en`,
}

// props type checking
SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string,
}

// exports
export default SEO
